import * as React from "react";
import SearchSuggestionBox from "../../search-suggestion-box";
import { Icon } from "@blueprintjs/core";
import GenomeTile from "../../genome-tile";
import { performSearch } from "../../../utils/search";

class ATCCHomePage extends React.PureComponent {
  renderGenomeTiles = () => {
    const genomes = [
      {
        name: "Actinomadura fibrosa (ATCC® 49459™)",
        metadataText: "Added 7/17/2024",
        uuid: "618c97aa1151408c",
        iconName: "tom",
      },
      {
        name: "Japanese encephalitis virus (ATCC® VR-74™)",
        metadataText: "Added 7/17/2024",
        uuid: "364d2a04e9f54974",
        iconName: "covid-19",
      },
      {
        name: "Delftia acidovorans bacteriophage Phi W14 (ATCC® 9355-B1™)",
        metadataText: "Added 9/13/2024",
        uuid: "e84b9f414eae4143",
        iconName: "covid-19",
      },
    ];

    return genomes.map((g, idx) => {
      return (
        <GenomeTile
          name={g.name}
          metadataText={g.metadataText}
          idx={idx}
          key={g.uuid}
          uuid={g.uuid}
          iconName={g.iconName}
        />
      );
    });
  };

  render() {
    return (
      <div className="atcc-homepage">
        <div className="welcome">
          <h1>Welcome to the ATCC Genome Portal</h1>
          <p>The only authenticated reference genome database for ATCC microbes</p>
          <a className="view-genomes" href="/genomes">
            {"VIEW ALL GENOMES  "}
            <Icon icon="chevron-right" />
          </a>

          <h2>Search for a genome</h2>
          <SearchSuggestionBox performSearch={performSearch} />

          <div className="recently-updated">
            <h2>Recently published</h2>
            <div className="genome-tile-container">{this.renderGenomeTiles()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ATCCHomePage;
